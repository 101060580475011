@use 'sass:map';
@use '@angular/material' as mat;

$darkThemeBackgroundColor: map.get($grayColors, 10);
$darkThemeBackgroundColorAlternate: map.get($grayColors, 5);
$darkThemeBackgroundColorSolid: map.get($grayColors, 0);
$darkThemeBackgroundColorGray: map.get($grayColors, 20);
$darkThemeFontColor: map.get($grayColors, 100);

$darkThemeNormalLightness: 600;
$darkThemeAccentLightness: $darkThemeNormalLightness - 200;
$darkThemeBackgroundLightness: 900;

$darkThemeMainPalette: $nobilColors;
$darkThemeMainSecondPalette: $nobilSecondColors;
$darkThemeErrorPalette: $redColors;
$darkThemeWarningPalette: $yellowColors;
$darkThemeSuccessPalette: $greenColors;

$darkTheme: mat.define-dark-theme(
    (
        color: (
            primary:
                mat.define-palette(
                    $darkThemeMainPalette,
                    $darkThemeNormalLightness,
                    $darkThemeNormalLightness + 100,
                    $darkThemeNormalLightness - 100
                ),
            accent:
                mat.define-palette(
                    $darkThemeSuccessPalette,
                    $darkThemeNormalLightness,
                    $darkThemeNormalLightness + 100,
                    $darkThemeNormalLightness - 100
                ),
            warn:
                mat.define-palette(
                    $darkThemeErrorPalette,
                    $darkThemeNormalLightness,
                    $darkThemeNormalLightness + 100,
                    $darkThemeNormalLightness - 100
                )
        )
    )
);

body {
    --background-color: #{$darkThemeBackgroundColor};
    --background-color-alternate: #{$darkThemeBackgroundColorAlternate};
    --background-color-solid: #{$darkThemeBackgroundColorSolid};
    --background-color-solid-transparent-33: #{rgba($darkThemeBackgroundColorSolid, 0.2)};
    --background-color-solid-transparent-66: #{rgba($darkThemeBackgroundColorSolid, 0.5)};
    --background-color-gray: #{$darkThemeBackgroundColorGray};
    --font-color: #{$darkThemeFontColor};

    --main-color: #{map.get($darkThemeMainPalette, $darkThemeNormalLightness)};
    --main-color-accent: #{map.get($darkThemeMainPalette, $darkThemeAccentLightness)};
    --main-color-background: #{map.get($darkThemeMainPalette, $darkThemeBackgroundLightness)};

    --main-second-color: #{map.get($darkThemeMainSecondPalette, 500)};

    --error-color: #{map.get($darkThemeErrorPalette, $darkThemeNormalLightness)};
    --error-color-accent: #{map.get($darkThemeErrorPalette, $darkThemeAccentLightness)};
    --error-color-background: #{map.get($darkThemeErrorPalette, $darkThemeBackgroundLightness)};

    --warning-color: #{map.get($darkThemeWarningPalette, $darkThemeNormalLightness)};
    --warning-color-accent: #{map.get($darkThemeWarningPalette, $darkThemeAccentLightness)};
    --warning-color-background: #{map.get($darkThemeWarningPalette, $darkThemeBackgroundLightness)};

    --success-color: #{map.get($darkThemeSuccessPalette, $darkThemeNormalLightness)};
    --success-color-accent: #{map.get($darkThemeSuccessPalette, $darkThemeAccentLightness)};
    --success-color-background: #{map.get($darkThemeSuccessPalette, $darkThemeBackgroundLightness)};

    @include mat.all-component-themes($darkTheme);
}
