mat-error {
    font-size: $fontSizeXS;
    line-height: 16px;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-width: 300px;
        width: 100%;

        @include rwd(tablet) {
            max-width: 400px;
        }

        .mat-mdc-form-field {
            margin-bottom: 10px;
            width: 100%;

            .mat-mdc-text-field-wrapper {
                background: none;
                padding: 0;

                .mat-mdc-form-field-focus-overlay {
                    background: none;
                }
            }
        }

        .number-without-buttons {
            appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .submit-container {
            margin-top: 20px;
            align-self: center;
        }

        .text-error {
            text-align: center;
            margin-bottom: 10px;
        }
    }
}
