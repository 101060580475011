.section-header {
    font-size: $fontSizeXXXL;
    font-weight: 700;
    letter-spacing: 0;
    padding-bottom: 12px;
    position: relative;
    text-align: left;
    color: $mainColor;
    font-family: $specialFontFamily;
    text-transform: uppercase;

    @include rwd(laptop) {
        font-size: $fontSizeSubtitle;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5px;
        width: 55px;
        background-color: $mainSecondColor;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 2px;
        height: 1px;
        width: 95%;
        max-width: 255px;
        background-color: $mainSecondColor;
    }
}

.header-underline {
    position: relative;
    font-family: $specialFontFamily;

    &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        left: -4px;
        background-color: $mainColor;
        width: 80px;
        height: 4px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: -8px;
        background-color: $mainColor;
        width: 4px;
        height: 20px;
    }

    &.second {
        &::before {
            background-color: $mainSecondColor;
        }

        &::after {
            background-color: $mainSecondColor;
        }
    }
}
