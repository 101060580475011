// For smallest text only
$fontSizeXS: 12px;
// For longer texts
$fontSizeS: 14px;
// For UI elements and most of things
$fontSizeM: 16px;
// For headers level 3
$fontSizeL: 20px;
// For headers level 2
$fontSizeXL: 24px;
// For headers level 1
$fontSizeXXL: 28px;
// For bigger than headers level 1 and smaller than subtitle
$fontSizeXXXL: 36px;
//For subtitles
$fontSizeSubtitle: 42px;
//For titles
$fontSizeTitle: 50px;
// For titles on desktop
$fontSizeTitleDesktop: 72px;

// For social media icons
$iconSizeSocial: 44px;
// For small icons
$iconSizeS: 26px;
// For normal icons
$iconSizeM: 36px;
//For larger icons
$iconSizeL: 50px;
//For extra large icons
$iconSizeXL: 70px;
// For largest icons
$iconSizeXXL: 90px;

// Available weights: 400, 700
$robotoFontFamily: 'Roboto', sans-serif;
// Available weights: 400, 700
$specialFontFamily: 'Raleway', sans-serif;
